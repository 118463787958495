import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbbreviateTimezoneNamePipe } from '@shared/pipes/abbreviate-timezone/abbreviate-timezone-name.pipe';

@Injectable()
export class FullDateTimeWithTimezoneService {
  constructor(private datePipe: DatePipe, private abbreviateTimezoneNamePipe: AbbreviateTimezoneNamePipe) {}

  transformDate(date: any) {
    if (isNaN(date)) {
      return date;
    } else {
      return (
        this.datePipe.transform(date, 'medium') +
        ' ' +
        this.abbreviateTimezoneNamePipe.transform(Intl.DateTimeFormat().resolvedOptions().timeZone)
      );
    }
  }
}
