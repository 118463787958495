import { gql } from 'apollo-angular';

export const PACESETTER_WIDGET_QUERY = gql`
  fragment PaceSetterFields on PaceSetter {
    daysRemaining
    defaultTo
    endDate
    expirationDate
    lastUpdated
    numberSponsored
    qualPeriodOver
    requiredSponsored
    requiredVolume
    status
    totalVolume
    annual
  }

  query getPaceSetterWidgetData {
    paceSetterWidgetData: getPaceSetterWidgetData {
      pacesetter {
        ...PaceSetterFields
      }
      platinum {
        ...PaceSetterFields
      }
    }
  }
`;
