import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PacesetterWidgetModel } from '@common/pacesetter/pacesetter-widget.model';
import { Apollo } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PACESETTER_WIDGET_QUERY } from './pacesetter-widget.graph';

@Injectable({
  providedIn: 'root',
})
export class PacesetterWidgetService {
  constructor(private http: HttpClient, private apollo: Apollo) {}

  private static handleError(error: HttpErrorResponse) {
    return throwError(error.error.message);
  }

  getPacesetterStatusData(locale): Observable<PacesetterWidgetModel> {
    return this.http.get('/dlm2/rest/pacesetterWidget/status', this.getHeaders(locale)).pipe(
      map((pacesetterData: PacesetterWidgetModel) => pacesetterData),
      catchError(PacesetterWidgetService.handleError)
    );
  }

  getPacesetterWidgetData(): Observable<PacesetterWidgetModel> {
    return this.apollo
      .watchQuery<{ paceSetterWidgetData: PacesetterWidgetModel }>({
        query: PACESETTER_WIDGET_QUERY,
      })
      .valueChanges.pipe(map((result) => result.data?.paceSetterWidgetData));
  }

  getHeaders(locale) {
    return {
      headers: {
        Accept: 'application/json;charset=UTF-8',
        'Content-Type': 'application/json;charset=UTF-8',
        'Content-Language': locale,
      },
    };
  }
}
