import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FullDateTimeWithTimezoneService } from '@common/custom-locale-pipes/full-date-time-with-timezone/full-date-time-with-timezone.service';
import { FullDateTimeWithTimezonePipe } from '@common/custom-locale-pipes/full-date-time-with-timezone/pipe/full-date-time-with-timezone.pipe';
import { AbbreviateTimezoneNamePipe } from '@shared/pipes/abbreviate-timezone/abbreviate-timezone-name.pipe';

@NgModule({
  declarations: [FullDateTimeWithTimezonePipe],
  exports: [FullDateTimeWithTimezonePipe],
  imports: [CommonModule],
  providers: [AbbreviateTimezoneNamePipe, FullDateTimeWithTimezoneService],
})
export class FullDateTimeWithTimezoneModule {}
