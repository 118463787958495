import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'abbreviateTimezoneName',
})
export class AbbreviateTimezoneNamePipe implements PipeTransform {
  transform(timezoneName: string): string {
    return DateTime.now().setZone(timezoneName).offsetNameShort;
  }
}
