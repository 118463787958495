import { Pipe, PipeTransform } from '@angular/core';
import { FullDateTimeWithTimezoneService } from '@common/custom-locale-pipes/full-date-time-with-timezone/full-date-time-with-timezone.service';

@Pipe({
  name: 'fullDateTimeWithTimezone',
})
export class FullDateTimeWithTimezonePipe implements PipeTransform {
  constructor(private fullDateWithTimezoneService: FullDateTimeWithTimezoneService) {}

  transform(date: any, ...args: any[]): any {
    return this.fullDateWithTimezoneService.transformDate(date);
  }
}
